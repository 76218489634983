import img1 from '../Assets/picnic-2.jpg'
import img2 from '../Assets/picnic-img1.jpg'
import img3 from '../Assets/picnic-3.jpg'
import img4 from '../Assets/picnic-4.jpg'


export const resourcedata = [
    {
        image:img1
    },
    {
        image:img2
    },
    {
        image:img3
    },
    {
        image:img4
    },
]