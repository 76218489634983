import item1 from '../Assets/Victor-img.jpeg'
import item2 from '../Assets/Njide-img.jpeg'
import item3 from '../Assets/emeka-img.jpeg'
export const trusteesdatas = [
    {
        image:item1,
        details:'Victor Okpara',
        title:'Chairman, Board of trustees'
    },
    {
        image:item2,
        details:'Cynthia Njideka Anieke',
        title:'Secretary, Board of trustees'
    },
    {
        image:item3,
        details:'Emeka Ejiofor',
        title:'Member, Board of trustees'
    },
]

