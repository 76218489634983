import item1 from '../Assets/mypix.png'
import item2 from '../Assets/mypix.png'
import item3 from '../Assets/mypix.png'
import item4 from '../Assets/mypix.png'
import item5 from '../Assets/mypix.png'
import item6 from '../Assets/mypix.png'
import item7 from '../Assets/mypix.png'
import item8 from '../Assets/mypix.png'
import item9 from '../Assets/mypix.png'
export const memberdatas = [
    {
        image: item1,
        name:'Adaobi'
    },
    {
        image: item2,
        name:'Adaobi'
    },
    {
        image: item3,
        name:'Adaobi'
    },
    {
        image: item4,
        name:'Adaobi'
    },
    {
        image: item5,
        name:'Adaobi'
    },
    {
        image: item6,
        name:'Adaobi'
    },
    {
        image: item7,
        name:'Adaobi'
    },
    {
        image: item8,
        name:'Adaobi'
    },
    {
        image: item9,
        name:'Adaobi'
    },
]