import React from 'react'
import './Error.css'


function Error() {
  return (
    <div className="error">
      <h2>
      OOOPS! <br/>
      Wrong Page! You are either using a broken path or your system is broken!!!
      </h2>

      </div>
  )
}

export default Error